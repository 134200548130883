// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-gallery-page-js": () => import("./../src/templates/gallery-page.js" /* webpackChunkName: "component---src-templates-gallery-page-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-service-js": () => import("./../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-cities-js": () => import("./../src/templates/cities.js" /* webpackChunkName: "component---src-templates-cities-js" */),
  "component---src-templates-service-city-js": () => import("./../src/templates/service-city.js" /* webpackChunkName: "component---src-templates-service-city-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-thanks-js": () => import("./../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

